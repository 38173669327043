import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './hubadmin/layout/layout.component';
import { SigninComponent } from './common/signin/signin.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { ReportsModule } from './reports/reports.module';
import { AddNewProgramComponent } from './common/add-new-program/add-new-program.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'create-new-program',
    component: AddNewProgramComponent,
  },
  {
    path: 'hubAdmin',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./hubadmin/hubadmin.module').then(m => m.HubadminModule)
  },
  {
    path: 'agent',  
    canActivate: [AuthGuardService],
    loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule)
  },
  {
    path: 'teamleader',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./team-leader/team-leader.module').then(m => m.TeamLeaderModule)
  },
  {
    path: 'redemption',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./redemption/redemption.module').then(m => m.RedemptionModule)
  },
  {
    path: 'reports',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
