import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { DatePipe } from "@angular/common";
import {
  CHANNEL_EXCEL_HEADER,
  CITY_EXCEL_HEADER,
  CITY_UPLOAD_HEADER,
  GEOGRAPHY_EXCEL_HEADER,
  LOYALTY_PRODUCT_HEADER,
  ORDER_DETAILS_EXCEL,
  ORDER_DETAILS_EXCEL_NOT_BEAT,
  POINT_STRUCTURE_EXCEL,
  PRIVILEGE_UPLOAD_HEADER,
  PRODUCT_EXCEL_HEADER,
  REGION_EXCEL_HEADER,
  REGION_UPLOAD_HEADER,
  REWARDS_BRAND_EXCEL,
  REWARDS_CATEGORIES_EXCEL,
  REWARDS_SUB_CATEGORIES_EXCEL,
  STATE_EXCEL_HEADER,
  STATE_UPLOAD_HEADER,
  TARGET_EXCEL,
  TARGET_ACHIEVEMENT_EXCEL,
  USER_EXCEL,
  DISTRICT_EXCEL_HEADER,
} from "src/app/hubadmin/shared/models/mock-data";
import { BrandModel } from "src/app/hubadmin/shared/models/brands";
import { ScanModel } from "src/app/hubadmin/shared/models/scan";
import { GeographyModel } from "src/app/hubadmin/shared/models/geography";
import { ChannelModel } from "src/app/hubadmin/shared/models/channel";
import { RegionModel } from "src/app/hubadmin/shared/models/region";
import { UserRoleModel } from "src/app/hubadmin/shared/models/user-roles";
import { RewardProvider } from "src/app/hubadmin/shared/providers/rewards.provider";
declare var require: any
const FileSaver = require('file-saver');

@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  // Redmeption
  TargetHeaders = TARGET_EXCEL;
  TargetAchieveHeaders = TARGET_ACHIEVEMENT_EXCEL;
  PointStructureHeaders = POINT_STRUCTURE_EXCEL;
  userHeaders = USER_EXCEL;
  stateHeaders = STATE_EXCEL_HEADER;
  districtHeader = DISTRICT_EXCEL_HEADER
  productHeaders = PRODUCT_EXCEL_HEADER;
  geoHeaders = GEOGRAPHY_EXCEL_HEADER;
  regionHeaders = REGION_EXCEL_HEADER;
  channelHeaders = CHANNEL_EXCEL_HEADER;
  cityHeaders = CITY_EXCEL_HEADER;
  regionUploadHeaders = REGION_UPLOAD_HEADER;
  stateUploadHeaders = STATE_UPLOAD_HEADER;
  cityUploadHeaders = CITY_UPLOAD_HEADER;
  loyaltyProductHeaders = LOYALTY_PRODUCT_HEADER;
  privilegeHeaders = PRIVILEGE_UPLOAD_HEADER;

  hasBeat: any;

  constructor(
    private datePipe: DatePipe,
    public rewardProviders: RewardProvider,
  ) {

    this.hasBeat = localStorage.getItem("has_beat");
  }

  pipe = new DatePipe("en-US");

  exportExcel(brandData: BrandModel[]) {
    const header = this.TargetHeaders;
    const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    const Brands = filterBrands.map((i) => i.sku);
    const finalHeaders = header.concat(Brands);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Target Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Target_Excel-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  exportScanEarnExcel(brandData: ScanModel[]) {
    const header = this.TargetHeaders;
    const finalHeaders = header.concat();
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Target Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Target_Excel-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  exportAchievementsExcel(brandData: BrandModel[]) {
    const header = this.TargetAchieveHeaders;
    // const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    // const Brands = filterBrands.map((i) => i.sku);
    const finalHeaders = header;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Sales Achievement Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F79494" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // worksheet.getCell('C2').value = {}

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Sales_Achievements_Excel-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportPointExcel(brandData: BrandModel[], outletCount: any) {
    const finalHeaders = this.PointStructureHeaders;
    const productHeaders = this.loyaltyProductHeaders;
    const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Point Structure Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /****************************PRODUCTS*************************************/

    let productWorksheet = workbook.addWorksheet("Loyalty_Products", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(this.loyaltyProductHeaders);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [
      { header: "Product Name", key: "brand_desc" },
      { header: "SKU", key: "sku" },
      { header: "Product Short Name", key: "brand_name" },
      // { header: "Unit", key: "unit_id" },
    ];

    brandData.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    /*************************************************************************/

    // let productFormulae = [
    //   `${productWorksheet.name}!B2:B${filterBrands.length + 1}`,
    // ];

    // let iterationLength = filterBrands.length + 1;

    // if(outletCount > 0) {

    //   iterationLength = outletCount * filterBrands.length + 1;
    // }

    // for (var i = 2; i <= iterationLength; i++) {
    //   worksheet.getCell(`B${i}`).dataValidation = {
    //     type: "list",
    //     allowBlank: false,
    //     formulae: productFormulae,
    //     errorStyle: "error",
    //     errorTitle: "choose a role",
    //     error: "please select",
    //     showErrorMessage: true,
    //   };
    // }

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Point_Structure_Excel-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  Order_finalHeaders: any;
  exportOrderDetailsExcel() {
    if (this.hasBeat == 0) {
      this.Order_finalHeaders = ORDER_DETAILS_EXCEL_NOT_BEAT;
    } else if (this.hasBeat == 1) {
      this.Order_finalHeaders = ORDER_DETAILS_EXCEL;
    }
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Order Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(this.Order_finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /****************************PRODUCTS*************************************/

    let productWorksheet = workbook.addWorksheet("Status", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let status = [
      { id: 1, name: "Placed" },
      { id: 2, name: "Accepted" },
      { id: 3, name: "Fulfilled" },
      { id: 4, name: "Rejected" },
      { id: 0, name: "Pending" }
    ]

    let STATUS = ['Status']
    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(STATUS);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [
      { header: "Status", key: "name" },
    ];

    status.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });



    /*************************************************************************/

    let productFormulae = [
      `${productWorksheet.name}!A2:A${status.length + 1}`,
    ];


    for (var i = 2; i <= 50; i++) {
      worksheet.getCell(`E${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: productFormulae,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };
    }

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Order_Details-Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  exportRewardSubCategories() {
    const finalHeaders = REWARDS_SUB_CATEGORIES_EXCEL;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Rewards Sub Categories", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    /**************************** DropDown*************************************/

    let productWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    let category = this.rewardProviders.rewardCategory;
    let CAT_ADD = ['Category']

    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(CAT_ADD);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [
      { header: "Category", key: "category_name" },
    ];

    category.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    let productFormulae = [
      `${productWorksheet.name}!A2:A${category.length + 1}`,
    ];


    for (var i = 2; i <= 50; i++) {
      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: productFormulae,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };
    }

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Rewards_Sub_Categories_Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  //Sub-Cat xl doqnloadEnd =====================


  exportRewardBrand() {
    const finalHeaders = REWARDS_BRAND_EXCEL;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Reward Brands", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });



    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Rewards_Brands_Monthly_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportUserExcel(
    geography: GeographyModel[],
    channel: ChannelModel[],
    region: RegionModel[],
    role: UserRoleModel[],
    state: any[],
    city: any[]
  ) {
    const finalHeaders = this.userHeaders;
    const geographies = geography.map((i) => i.geograph_name.trim()).join(",");
    const channels = channel.map((i) => i.channel_desc.trim()).join(",");
    const regions = region.map((i) => i.region_name.trim()).join(",");
    const roles = role.map((i) => i.role_name.trim()).join(",");
    const cities = city.map((i) => i.cityname.trim()).join(",");
    const states = state.map((i) => i.state_name.trim()).join(",");

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Users", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /******GEO WORKSHEET********/
    let geographyWorksheet = workbook.addWorksheet("Geography", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let geoHeaderRow = geographyWorksheet.addRow(this.geoHeaders);

    // Cell Style : Fill and Border
    geoHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    geographyWorksheet.columns = [
      { header: "Geography", key: "geograph_name" },
    ];

    geography.forEach((d) => {
      let row = geographyWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    geographyWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /******GEO WORKSHEET********/

    /************STATE WORKSHEET***********************/

    let stateWorksheet = workbook.addWorksheet("States", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(this.stateHeaders);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "States", key: "state_name" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    /************CHANNEL WORKSHEET***********************/

    let channelWorksheet = workbook.addWorksheet("Channels", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let channelHeaderRow = channelWorksheet.addRow(this.channelHeaders);

    // Cell Style : Fill and Border
    channelHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    channelWorksheet.columns = [{ header: "Channels", key: "channel_desc" }];

    channel.forEach((d) => {
      let row = channelWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    channelWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    /************REGION WORKSHEET***********************/

    let regionWorksheet = workbook.addWorksheet("Zone", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let regionHeaderRow = regionWorksheet.addRow(this.regionHeaders);

    // Cell Style : Fill and Border
    regionHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    regionWorksheet.columns = [{ header: "Zone", key: "region_name" }];

    region.forEach((d) => {
      let row = regionWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    regionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    /************CITY WORKSHEET***********************/

    let cityWorksheet = workbook.addWorksheet("Cities", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let cityHeaderRow = cityWorksheet.addRow(this.cityHeaders);

    // Cell Style : Fill and Border
    cityHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    cityWorksheet.columns = [{ header: "Cities", key: "cityname" }];

    city.forEach((d) => {
      let row = cityWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    regionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    // Specify list of valid values (One, Two, Three, Four).
    // Excel will provide a dropdown with these values.

    let rolesData = this.excelDataValidationSplit(roles);

    let GeoFormulae = [`${geographyWorksheet.name}!A2:A${geography.length}`];

    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length}`];

    let regionFormulae = [`${regionWorksheet.name}!A2:A${region.length}`];

    let cityFormulae = [`${cityWorksheet.name}!A2:A${city.length}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: rolesData,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`E${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: GeoFormulae,
        errorStyle: "error",
        errorTitle: "choose a geography",
        error: "please select",
        showErrorMessage: true,
      };
      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormulae,
        promptTitle: "Zone",
        prompt: "Select any region",
        showErrorMessage: true,
      };

      worksheet.getCell(`H${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`O${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        showErrorMessage: true,
        formulae: cityFormulae,
        errorStyle: "error",
        errorTitle: "choose a city",
        error: "please select",
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Add_User_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  excelDataValidationSplit(item) {
    let data = item.split(",").join(",");
    data = '"' + data + '"';
    return [`${data}`];
  }


  exportBrandExcel(headers, title, dropDown) {

    const finalHeaders = headers;
    let categoryHeader = ['Category'];
    let subCategoryHeader = ['Sub Category'];
    // let isLoyaltyHeader = ['is Loyalty'];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add New Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      // cell.protection = { locked: true };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });



    /*********************category Starts**********************************/
    /** adding category drop down */
    let categoryWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let categoryHeaderRow = categoryWorksheet.addRow(categoryHeader);

    // Cell Style : Fill and Border
    categoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    categoryWorksheet.columns = [{ header: 'Category', key: "category_name" }];

    // dropDown.category.forEach((d) => {
    //   let row = categoryWorksheet.addRow(d);
    //   (row.font = {
    //     name: "Calibri",
    //     size: 8,
    //   }),
    //     (row.alignment = {
    //       vertical: "middle",
    //       horizontal: "center",
    //     });
    // });

    categoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // let categoryFormulae = [`${categoryWorksheet.name}!A2:A${dropDown.category.length}`];

    // for (var i = 2; i < 50; i++) {
    //   worksheet.getCell(`H${i}`).dataValidation = {
    //     type: "list",
    //     allowBlank: false,
    //     formulae: categoryFormulae,
    //     errorStyle: "error",
    //     errorTitle: "choose a state",
    //     error: "please select",
    //     showErrorMessage: true,
    //   };
    // }

    /**************************************************************/

    let subCategoryWorksheet = workbook.addWorksheet("Sub_Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let subCategoryHeaderRow = subCategoryWorksheet.addRow(subCategoryHeader);

    // Cell Style : Fill and Border
    subCategoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    subCategoryWorksheet.columns = [{ header: "Sub Category", key: "sub_category_name" }];

    dropDown.subCategory.forEach((d) => {

      let row = subCategoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    subCategoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let subCategoryFormulae = [`${subCategoryWorksheet.name}!A2:A${dropDown.subCategory.length}`];

    // for (var i = 2; i < 50; i++) {
    //   worksheet.getCell(`K${i}`).dataValidation = {
    //     type: "list",
    //     allowBlank: false,
    //     formulae: subCategoryFormulae,
    //     errorStyle: "error",
    //     errorTitle: "choose a state",
    //     error: "please select",
    //     showErrorMessage: true,
    //   };
    // }


    //add  is loyalty drop

    // let isLoyaltyWorksheet = workbook.addWorksheet("Is_Loyalty", {
    //   pageSetup: {
    //     horizontalCentered: true,
    //     verticalCentered: true,
    //     paperSize: 9,
    //     orientation: "portrait",
    //     margins: {
    //       left: 0.3149606,
    //       right: 0.3149606,
    //       top: 0.3543307,
    //       bottom: 0.3543307,
    //       header: 0.3149606,
    //       footer: 0.3149606,
    //     },
    //   },
    // });

    //Add Header Row
    // let isLoyaltyHeaderRow = isLoyaltyWorksheet.addRow(isLoyaltyHeader);

    // Cell Style : Fill and Border
    // isLoyaltyHeaderRow.eachCell((cell, number) => {
    //   cell.fill = {
    //     type: "pattern",
    //     pattern: "solid",
    //     fgColor: { argb: "9999FF" },
    //     bgColor: { argb: "FF0000FF" },
    //   };
    //   cell.font = {
    //     bold: true,
    //     name: "Calibri",
    //     size: 8,
    //   };
    //   cell.alignment = {
    //     vertical: "middle",
    //     horizontal: "center",
    //   };
    //   cell.border = {
    //     top: { style: "thin" },
    //     left: { style: "thin" },
    //     bottom: { style: "thin" },
    //     right: { style: "thin" },
    //   };
    // });

    //isLoyaltyWorksheet.columns = [{ header: "Is Loayalty", key: "isLoyality_name", values: dropDown.isLoyaltyDrop.value, }];

    // dropDown.isLoyaltyDrop.forEach((d) => {

    //   let row = isLoyaltyWorksheet.addRow(d);
    //   (row.font = {
    //     name: "Calibri",
    //     size: 8,
    //   }),
    //     (row.alignment = {
    //       vertical: "middle",
    //       horizontal: "center",
    //     });
    // });

    // let isLoyaltyFormulae = [`${isLoyaltyWorksheet.name}!A2:A${dropDown.isLoyaltyDrop.length + 1}`];

    // for (var i = 2; i <= 50; i++) {
    //   worksheet.getCell(`H${i}`).dataValidation = {
    //     type: "list",
    //     allowBlank: false,
    //     formulae: isLoyaltyFormulae,
    //     errorStyle: "error",
    //     errorTitle: "choose Loyality type",
    //     error: "please select",
    //     showErrorMessage: true,
    //   };
    // }

    // isLoyaltyWorksheet.columns.forEach(function (column, i) {
    //   column.width = 26;
    // });



    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        title + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportRegionExcel() {
    const finalHeaders = this.regionUploadHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Zone Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Zone_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportStateExcel(region: any[]) {
    const finalHeaders = this.stateUploadHeaders;


    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("State Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /************REGION WORKSHEET***********************/

    let regionWorksheet = workbook.addWorksheet("Zone", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let regionHeaderRow = regionWorksheet.addRow(this.regionHeaders);

    // Cell Style : Fill and Border
    regionHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    regionWorksheet.columns = [{ header: "Zone", key: "region_name" }];

    region.forEach((d) => {
      let row = regionWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    regionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**************************************************************/

    let regionFormulae = [`${regionWorksheet.name}!A2:A${region.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormulae,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "State_Excel_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  exportPriviligeExcel(state) {
    const finalHeaders = this.privilegeHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Product Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    //add dropdown
    let stateWorksheet = workbook.addWorksheet("Product_Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(this.productHeaders);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "Product Category", key: "field_name" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 11,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`F${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose Product Category",
        error: "please select",
        showErrorMessage: true,
      };
    }

    let venueWorksheet = workbook.addWorksheet("Venue Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });


    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Privilege_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }



  exportTargetsExcel(headers, categories) {

    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("State Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /************Categories WORKSHEET***********************/

    let categoryWorkSheet = workbook.addWorksheet("Targets", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    // Add Header Row

    let catHeaderRow = categoryWorkSheet.addRow([]);

    // Cell Style : Fill and Border
    catHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    categoryWorkSheet.columns = [{ header: "", key: "brand_category_name" }];

    categories.forEach((d) => {
      let row = categoryWorkSheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    categoryWorkSheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    let regionFormulae = [`${categoryWorkSheet.name}!A2:A${categories.length}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormulae,
        errorStyle: "error",
        errorTitle: "Category Required",
        error: "Please select a category",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "State_Excel_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportCityExcel(state: any[], district: any = []) {
    const finalHeaders = this.cityUploadHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("City Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**************************************************************/

    let stateWorksheet = workbook.addWorksheet("States", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(this.stateHeaders);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "States", key: "state_name" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }





    //===========district dropdown ============================

    let districtWorksheet = workbook.addWorksheet("District", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let districtHeaderRow = districtWorksheet.addRow(this.districtHeader);

    // Cell Style : Fill and Border
    districtHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    districtWorksheet.columns = [{ header: "District", key: "district_name" }];

    district.forEach((d) => {
      let row = districtWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    districtWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let districtFormulae = [`${districtWorksheet.name}!A2:A${district.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: districtFormulae,
        errorStyle: "error",
        errorTitle: "choose a district",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "City_Excel_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportRewardsExcel(headers, title, dropDown) {

    const finalHeaders = headers;
    let categoryHeader = ['Category'];
    let subCategoryHeader = ['Sub Category'];
    let brandHeader = ['Brands'];
    let priceHeader = ['Price'];
    let productHeader = ['Product Types'];
    let catalogueHeader = ['Catalogue'];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add New Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      // cell.protection = { locked: true };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });



    /*********************category Starts**********************************/
    /** adding category drop down */
    let categoryWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let categoryHeaderRow = categoryWorksheet.addRow(categoryHeader);

    // Cell Style : Fill and Border
    categoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    categoryWorksheet.columns = [{ header: 'Category', key: "category_name" }];

    dropDown.category.forEach((d) => {
      let row = categoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    categoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let categoryFormulae = [`${categoryWorksheet.name}!A2:A${dropDown.category.length + 1}`];

    /**************************************************************/

    let subCategoryWorksheet = workbook.addWorksheet("Sub_Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let subCategoryHeaderRow = subCategoryWorksheet.addRow(subCategoryHeader);

    // Cell Style : Fill and Border
    subCategoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    subCategoryWorksheet.columns = [{ header: "Sub Category", key: "sub_category_name" }];

    dropDown.subCategory.forEach((d) => {

      let row = subCategoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    subCategoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let subCategoryFormulae = [`${subCategoryWorksheet.name}!A2:A${dropDown.subCategory.length + 1}`];


    /**************************** Brands **********************************/

    let brandWorksheet = workbook.addWorksheet("Brands", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let brandHeaderRow = brandWorksheet.addRow(brandHeader);

    // Cell Style : Fill and Border
    brandHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    brandWorksheet.columns = [{ header: "Brands", key: "brand_name" }];

    dropDown.brands.forEach((d) => {

      let row = brandWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    brandWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let brandFormulae = [`${brandWorksheet.name}!A2:A${dropDown.brands.length + 1}`];



    /**************************** Price Drop down **********************************/

    /**********************Product types***********************************/

    let productWorksheet = workbook.addWorksheet("Product_Types", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(productHeader);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [{ header: "Product Types", key: "product_type" }];

    dropDown.product_types.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let productFormulae = [`${productWorksheet.name}!A2:A${dropDown.product_types.length + 1}`];



    //Add Header Row for  catalogue

    let catalogueWorksheet = workbook.addWorksheet("catalogue", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    let catalogueHeaderRow = catalogueWorksheet.addRow(catalogueHeader);

    // Cell Style : Fill and Border
    catalogueHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    catalogueWorksheet.columns = [{ header: "Catalogue", key: "is_catalogue" }];

    dropDown.catalogue.forEach((d) => {
      let row = catalogueWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    catalogueWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let catalogueFormulae = [`${catalogueWorksheet.name}!A2:A${dropDown.product_types.length + 1}`];


    /**Adding drop into cells */
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`E${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: categoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`F${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: subCategoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: brandFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: productFormulae,
        errorStyle: "error",
        errorTitle: "choose a Product",
        error: "please select",
        showErrorMessage: true,
      };


      worksheet.getCell(`L${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: catalogueFormulae,
        errorStyle: "error",
        errorTitle: "choose a Calapouge",
        error: "please select",
        showErrorMessage: true,
      };

    }
    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        title + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  public DownloadReportExcel(name, tableData, excelArray) {
    let header = tableData.headers

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "1E88E5" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.body.length > 0) {
      tableData.body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name + "- HubAdmin -" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }




  public DownloadReportExcelForCarton(name, tableData, excelArray) {
    let header = tableData.headers

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });

      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  /**
   * @param brandCategories
   *
   */
  exportbrandCategoriesExcel(header, excelName) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add Product Category Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        excelName + "_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  // *****************************************************************************************
  // COMMON FUNCTION, pass headers
  commonPrintHeaders(headers) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('headers', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });




    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Template-" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  commonPrintHeadersTwo(headers, filename, sheetname) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        filename + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportInfluencer_qrExcel(headers) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('headers', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });




    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Influencer_qr-" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  // Rejected users
  exportRejectedUsersExcel(excelData) {
    const finalHeaders = excelData[0].headers;
    // const states = state.map((i) => i.state_name.trim()).join(",");

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData[0].name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = excelData[0].column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData[0].body.length > 0) {
      excelData[0].body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    /************Other roles***********************/

    excelData.shift();

    excelData.forEach((userData, i) => {
      let otherRoleSheet = workbook.addWorksheet(userData.name, {
        pageSetup: {
          horizontalCentered: true,
          verticalCentered: true,
          paperSize: 9,
          orientation: "portrait",
          margins: {
            left: 0.3149606,
            right: 0.3149606,
            top: 0.3543307,
            bottom: 0.3543307,
            header: 0.3149606,
            footer: 0.3149606,
          },
        },
      });

      //Add Header Row
      let stateHeaderRow = otherRoleSheet.addRow(userData.headers);

      // Cell Style : Fill and Border
      stateHeaderRow.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9999FF" },
          bgColor: { argb: "FF0000FF" },
        };
        cell.font = {
          bold: true,
          name: "Calibri",
          size: 8,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      otherRoleSheet.columns = userData.column;

      userData.body.forEach((d) => {
        let row = otherRoleSheet.addRow(d);
        (row.font = {
          name: "Calibri",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });

      otherRoleSheet.columns.forEach(function (column, i) {
        column.width = 26;
      });

    });


    /***************************************************/



    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Add_User_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  // Failed useres export

  exportFailedEnrollmentsExcel(excelData, column) {
    const finalHeaders = excelData.headers;
    // const states = state.map((i) => i.state_name.trim()).join(",");

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Failed enrollments', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData.failed_verifications.length > 0) {
      excelData.failed_verifications.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Add_User_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  // Export excel general

  exportExcel_with_header(headers, excel_name) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excel_name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        excel_name + "_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  // *************************************************************************************************


  // *************** Common download with body and optional dropdown usage ****************
  /**
   * @auth sujay
   */

  // This function is used to download excel with header and data.
  // this can also be used to add dropdowns
  // data format for without dropdowns is as follows
  // excelData: {
  // headers: ['header1', 'header 2', '...'],
  // column: [{key: 'first_name'},{key: 'second_name'},{key: 'other_coloumn_keys'}], //this should be the key names that we want to print from body 
  // body: [{data}, {data}],
  // hasDropdown: true/false
  // file


  // if hasDropdown is true, we need to pass a dropdowns array as well
  // dropdown.name: 'eg: Zone'
  // dropdown.key: 'eg: region_name'
  // dropdown.column: 'D' //column in excel file
  // dropdown.body: [{data}, {data}]
  // }

  commonDownloadWithData(excelData) {
    const finalHeaders = excelData.headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData.name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });



    worksheet.columns = excelData.column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData.body.length > 0) {
      excelData.body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    // only if hasDropdown param is true this code is executed
    if (excelData.hasDropdown == true) {
      excelData.dropDowns.forEach(dropdown => {

        let commonWorksheet = workbook.addWorksheet(dropdown.name, {
          pageSetup: {
            horizontalCentered: true,
            verticalCentered: true,
            paperSize: 9,
            orientation: "portrait",
            margins: {
              left: 0.3149606,
              right: 0.3149606,
              top: 0.3543307,
              bottom: 0.3543307,
              header: 0.3149606,
              footer: 0.3149606,
            },
          },
        });

        //Add Header Row
        let commonHeaderRow = commonWorksheet.addRow(dropdown.name);

        // Cell Style : Fill and Border
        commonHeaderRow.eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "9999FF" },
            bgColor: { argb: "FF0000FF" },
          };
          cell.font = {
            bold: true,
            name: "Calibri",
            size: 8,
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        commonWorksheet.columns = [{ header: dropdown.name, key: dropdown.key }];

        dropdown.body.forEach((d) => {
          let row = commonWorksheet.addRow(d);
          (row.font = {
            name: "Calibri",
            size: 8,
          }),
            (row.alignment = {
              vertical: "middle",
              horizontal: "center",
            });
        });

        commonWorksheet.columns.forEach(function (column, i) {
          column.width = 26;
        });


        let Formulae = [`${dropdown.name}!A2:A${dropdown.body.length + 1}`];

        for (var i = 2; i < 50; i++) {
          worksheet.getCell(`${dropdown.column}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: Formulae,
            errorStyle: "error",
            errorTitle: "choose a status",
            error: "please select",
            showErrorMessage: true,
          };
        }

      });
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        `${excelData.file}` + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }



  public DownloadstaticReportExcel(name, sheetname, tableData, excelArray) {
    let header = tableData.headers

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });

      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }



  cartanSpecialOcceranceExcel(state, excelHeader, productHeader, fileName, sheetName1, sheetName2, excelStatic) {

    const finalHeaders = excelHeader;

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetName1, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });


    // static row
    //Add Header Row
    let staticRow = worksheet.addRow(excelStatic);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    staticRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFff" },
        bgColor: { argb: "FFFFFF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });



    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    /************STATE WORKSHEET***********************/

    let stateWorksheet = workbook.addWorksheet(sheetName2, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(productHeader);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "Product Type", key: "product_type" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });


    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length + 1}`];

    for (var i = 2; i < 50; i++) {


      worksheet.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };


    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        fileName + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }
  GenerateEmptyExcelFromObject(headersObject: Record<string, string>, sheetName: string) {
    const workbook = new Workbook(); // Create a new workbook
    const worksheet = workbook.addWorksheet(sheetName, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9, // A4 paper size
        orientation: 'portrait',
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    // Get the headers dynamically from the object keys
    const headers = Object.values(headersObject); // Get headers from object values

    // Add the headers as the first row in the worksheet
    const headerRow = worksheet.addRow(headers);

    // Apply styles to header row cells
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' }, // Yellow foreground color
        bgColor: { argb: 'FF0000FF' }, // Blue background color
      };
      cell.font = {
        bold: true,
        name: 'Calibri',
        size: 10, // Larger font size for better readability
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    // Adjust column widths based on header lengths
    worksheet.columns.forEach((column, index) => {
      column.width = headers[index].length + 5; // Additional width for readability
    });

    // Save the workbook as a Blob and download it
    workbook.xlsx.writeBuffer().then((buffer) => {
      const dataBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(
        dataBlob,
        `${sheetName}_${this.datePipe.transform(new Date(), 'yyyyMMdd_HHmmss')}.xlsx`
      );
    });
  }

  rewardExcelData(excelData) {
    const finalHeaders = excelData.sheet1.Headers;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelData.sheet1.sheetname);

    // Add Header Row 
    let headerRow = worksheet.addRow(finalHeaders);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };

      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };

      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = finalHeaders;
    worksheet.columns.forEach((column) => {
      column.width = 26;
    });

    /**************************Categories************************************/

    let categoriesWorksheet = workbook.addWorksheet(excelData.sheet2.sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let categoriesHeaderRow = categoriesWorksheet.addRow(excelData.sheet2.header);
    // Cell Style : Fill and Border
    categoriesHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    categoriesWorksheet.columns = [{ header: "Categories", key: "name" }];
    excelData.sheet2.category.forEach((d) => {
      let row = categoriesWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });
    categoriesWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });
    let categoriesFormulae = [`${categoriesWorksheet.name}!A2:A${excelData.sheet2.category.length + 1}`];
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`A${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: categoriesFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    /****************Product Type****************88 */
    let ProductTypeWorksheet = workbook.addWorksheet(excelData.sheet3.sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    let ProductTypeHeaderRow = ProductTypeWorksheet.addRow(excelData.sheet3.header);
    ProductTypeHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ProductTypeWorksheet.columns = [{ header: "Product Type", key: "name" }];
    excelData.sheet3.category.forEach((d) => {
      let row = ProductTypeWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });
    ProductTypeWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });
    let ProductTypeFormulae = [`${ProductTypeWorksheet.name}!A2:A${excelData.sheet3.category.length + 1}`];
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: ProductTypeFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    /****************Product By****************88 */

    let ProductByWorksheet = workbook.addWorksheet(excelData.sheet4.sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    let ProductByHeaderRow = ProductByWorksheet.addRow(excelData.sheet4.header);
    ProductByHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    ProductByWorksheet.columns = [{ header: "Product By", key: "name" }];
    excelData.sheet4.category.forEach((d) => {
      let row = ProductByWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });
    ProductByWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });
    let ProductByFormulae = [`${ProductByWorksheet.name}!A2:A${excelData.sheet4.category.length + 1}`];
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`J${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: ProductByFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      fs.saveAs(
        blob,
        excelData.excelname + '_' + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  DownloadPointsStructureTypeExcel(excelData, brands) {
    const finalHeaders = excelData.Headers;
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelData.sheetname);

    // Add Header Row 
    let headerRow = worksheet.addRow(finalHeaders);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };

      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };

      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = finalHeaders;
    worksheet.columns.forEach((column) => {
      column.width = 26;
    });

    /**************************Brands************************************/

    let brandsWorksheet = workbook.addWorksheet('Brands', {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let brandsHeaderRow = brandsWorksheet.addRow('Brands');
    // Cell Style : Fill and Border
    brandsHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 10,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    brandsWorksheet.columns = [{ header: "Brands", key: "brand_name" }];
    brands.forEach((d) => {
      let row = brandsWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 10,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });
    brandsWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });
    let brandsFormulae = [`${brandsWorksheet.name}!A2:A${brands.length + 1}`];
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`A${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: brandsFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      fs.saveAs(
        blob,
        excelData.sheetname + '_' + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  /**
   * @ayush  Download Excel Reading JSon Data and Download Excel with Sheets and Optional Dropdowns 
   * 
   * 
   * Example 
   * let excelData = {}
    excelData['fileName'] = 'Target Upload'
    excelData['name'] = 'target'
    excelData['body'] = []
    excelData['multipleSheets'] = true
    excelData['sheets'] = [{
      name : 'Brands',
      key : 'brand_name',
      hasDropdown : false,
      column : '',
      body : this.brands,
    }]
    if (targetType == 1) {
      excelData['headers'] = ['Outlet Code', 'Mobile Number','Month','Year',...this.brandsName]
    } else if (targetType == 2) {
      excelData['headers'] = ['Outlet Code', 'Mobile Number', 'Quarter Name',...this.brandsName]
      let sheet = {
        name : 'quarter',
        key : 'quarter_name',
        hasDropdown : true,
        column : 'C',
        body : this.Quater,
      }
      excelData['sheets'].push(sheet)
    } else if (targetType == 3) {
      excelData['headers'] = ['Outlet Code', 'Mobile Number', 'Cycle Name',...this.brandsName]
      let sheet = {
        name : 'cycle',
        key : 'target_cycle_name',
        hasDropdown : true,
        column : 'C',
        body : this.Cycle,
      }
      excelData['sheets'].push(sheet)
   */

  exportExcelWithData(headers: string[], data: any[], excel_name: string) {
    if (headers.length === 0 || data.length === 0) {
      console.error('Headers or data is empty.');
      return;
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excel_name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    // Add Header Row
    let headerRow = worksheet.addRow(headers);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Fill Data Rows
    data.forEach((item) => {
      let row = worksheet.addRow(Object.values(item));
      row.eachCell((cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      });
    });

    worksheet.columns.forEach((column) => {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((buffer) => {
      let blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const timestamp = this.datePipe.transform(new Date(), "medium").replace(/[ :]/g, "_");
      const filename = `${excel_name}_${timestamp}.xlsx`;

      fs.saveAs(blob, filename);
    }).catch((error) => {
      console.error('Error exporting Excel:', error);
    });
  }


  DownloadCommonExcelWithOptionalDropDown(excelData) {
    const finalHeaders = excelData.headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData.sheetName, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });



    worksheet.columns = finalHeaders
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData.body.length > 0) {
      excelData.body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    // only if multipleSheets is true this code is executed
    if (excelData.multipleSheets == true) {
      excelData.sheets.forEach(sheet => {
        let commonWorksheet = workbook.addWorksheet(sheet.name, {
          pageSetup: {
            horizontalCentered: true,
            verticalCentered: true,
            paperSize: 9,
            orientation: "portrait",
            margins: {
              left: 0.3149606,
              right: 0.3149606,
              top: 0.3543307,
              bottom: 0.3543307,
              header: 0.3149606,
              footer: 0.3149606,
            },
          },
        });

        //Add Header Row
        let commonHeaderRow = commonWorksheet.addRow(sheet.name);

        // Cell Style : Fill and Border
        commonHeaderRow.eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "9999FF" },
            bgColor: { argb: "FF0000FF" },

          };
          cell.font = {
            bold: true,
            name: "Calibri",
            size: 8,
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        commonWorksheet.columns = [{ header: sheet.name, key: sheet.key }];

        sheet.body.forEach((d) => {
          let row = commonWorksheet.addRow(d);
          (row.font = {
            name: "Calibri",
            size: 8,
          }),
            (row.alignment = {
              vertical: "middle",
              horizontal: "center",
            });
        });

        commonWorksheet.columns.forEach(function (column, i) {
          column.width = 26;
        });

        if (sheet.hasDropdown) {
          let Formulae = [`${sheet.name}!A2:A${sheet.body.length + 1}`];

          for (var i = 2; i < 50; i++) {
            worksheet.getCell(`${sheet.column}${i}`).dataValidation = {
              type: "list",
              allowBlank: false,
              formulae: Formulae,
              errorStyle: "error",
              errorTitle: "choose a status",
              error: "please select",
              showErrorMessage: true,
            };
          }
        }



      });
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        `${excelData.fileName} ` + '' + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }
  // Use this method to download data as CSV format 
  exportCsvWithData(headers: string[], data: any[], fileName: string) {
     if (headers.length === 0 || data.length === 0) {
      console.error("Headers or data is empty.");
      return;
    }

    let csvContent = "";

    // Add headers row
    csvContent += headers.join(",") + "\r\n";

    // Add data rows
    data.forEach((row) => {
      let rowData = headers.map((header) => {
        let cell = row[header]; // Ensure missing values are handled
        return `"${cell}"`; // Wrap values in quotes to handle commas
      });
      csvContent += rowData.join(",") + "\r\n";
    });

    // Convert content to Blob
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Generate timestamp for filename
    const timestamp = this.datePipe.transform(new Date(), "yyyyMMdd_HHmmss");
    const filename = `${fileName}_${timestamp}.csv`;

    // Download the CSV file
    FileSaver.saveAs(blob, filename);
  }

}